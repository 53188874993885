import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import Button from "../../components/Button";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";
import Surgery2 from "../../components/landingPage/Surgery2";
import Laser from "../../components/landingPage/Laser";

const GTM_LOCATION = "warszawa-laserowe";

const REVIEWS = [
  {
    author: "Tomasz S.",
    city: "Warszawa",
    review:
      "Szybko i bezboleśnie usunięto moje znamiona laserem. Lekarz był bardzo profesjonalny i miły podczas całego zabiegu. Efekty są naprawdę rewelacyjne. Z całego serca polecam!"
  },
  {
    author: "Krzysztof G.",
    city: "Warszawa",
    review:
      "Jestem bardzo zadowolony, że wybrałem Twoje Znamiona. Zdecydowałem się na usunięcie brodawek laserem. Zabieg był praktycznie bezbolesny, a przed usunięciem miałem badanie wideodermatoskopowe, żeby mieć pewność, że mogę usuwać zmiany laserowo."
  },
  {
    author: "Agnieszka D.",
    city: "Warszawa",
    review:
      "To było moje pierwsze doświadczenie z usuwaniem laserowym, i jestem pod wrażeniem tej metody. Zabieg przeszedł sprawnie i efektywnie. Zabieg wykonują lekarze o ogromnym doświadczeniu. Jestem bardzo zadowolona z efektów."
  },
  
];

const PRICES = [
  
  {
    isOpened: true,
    categoryName: "Laserowe usuwanie zmian skórnych",
    categorySub: "m.in brodawki, włókniaki, kurzajki",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="1f56c5e0-fc26-11ec-915c-d181b41bbf96"
      />
    ),
    items: [
      {
        price: "380",
        label: "usunięcie 1 zmiany skórnej do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "70",
        label:
          "*usunięcie dodatkowej zmiany skórnej w trakcie tej samej wizyty do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "430",
        label:
          "usunięcie 1 zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "430",
        label:
          "usunięcie 1 brodawki wirusowej - kurzajki",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej brodawki wirusowej - kurzajki w trakcie tej samej wizyty",
          sub: "w przypadku rozległych zmian o liczbie zabiegów decyduje lekarz",
      },
      {
        price: "200",
        label: "konsultacja lekarska",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="26155280-d1ff-11ec-a8d3-ebc463f9ed66"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów od 6 r.ż.",
      },
      {
        price: "550",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "330",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "650",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "380",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "300",
        label: "biopsja zmiany skórnej",
        
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Badanie znamion - wideodermatoskopia",
     
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="10f98eb0-fc26-11ec-915c-d181b41bbf96"
      />
    ),
    items: [
      {
        price: "450",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "350",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna osoby dorosłej do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
        data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje dermatologiczne",
    items: [
      {
        price: "250",
        label: "konsultacja dermatologiczna",
        sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
            data-apptnow-widget="33996350-fc26-11ec-915c-d181b41bbf96"
          />
        ),
      },
    ],
  },
];


const OFFERS = [
  {
    type: "niechirurgiczne",
    title: "Usuwanie laserowe",
    desc:
      "Nieinwazyjne zabiegi laserowe usuwania zmian skórnych pomogą Ci pozbyć się zmian takich jak włókniaki, brodawki czy kurzajki. Laserowe usuwanie pieprzyków jest zarezerwowane dla zmian łagodnych, a kwalifikację do zabiegu wykonuje lekarz.",
      link:"#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Każda niepokojąca zmiana skórna powinna być usunięta chirurgicznie. Zabieg polega na wycięciu zmiany wraz z fragmentem zdrowej skóry. W każdym przypadku wykonywane jest badania histopatologiczne, celem postawienia jednoznacznej diagnozy. ",
      link:"/warszawa/usuwanie-znamion-pieprzykow",
  },
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Wideodermatoskopowe badanie wszystkich znamion z mapowaniem zmian wytypowanych przez lekarza do obserwacji i usunięcia pomoże Ci zachować zdrowie, a powiększony dermatoskopowy obraz pomoże lekarzowi w wyborze odpowiedniej metody usunięcia Twoich zmian skórnych.",
      link:"/warszawa/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  },
];

const INFOS = [
  {
    title: "Ile trwa zabieg?",
    desc:
      "Zabieg laserowego usuwania zmian skórnych jest krótki, nie wymaga pobytu w szpitalu, trwa zwykle ok. 10 - 20 min.",
  },
  {
    title: "Jakie zmiany możemy usuwać laserowo?",
    desc:
      "Metoda laserowego usuwania znamion jest zarezerwowana dla zmian łagodnych takich m.in jak brodawki, włókniaki, kurzajki, kłykciny czy kępki żółte.",
  },
  {
    title: "Czy zabieg laserowy boli?",
    desc:
      "Zazwyczaj jest to zabieg niebolesny, może powodować uczucie lekkiego pieczenia lub mrowienia, możliwe jest też zastosowanie znieczulenia miejscowego.",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const WarszawaLaserPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title:
        "Czy laserowe usuwanie znamion zostawi blizny?",
      text:
        "Zastosowanie lasera CO2 minimalizuje ryzyko powstawania blizn, dzięki precyzyjnemu i kontrolowanemu procesowi leczenia. Po zabiegu zazwyczaj pozostają niewielkie zaczerwienienia, które stopniowo zanikają.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy muszę powtarzać zabieg, aby uzyskać trwałe efekty?",
      text:
        "W większości przypadków jednorazowe usuwanie jest skuteczne, jednak w zależności od rodzaju zmiany i indywidualnych czynników może być konieczne powtórzenie zabiegu. Lekarz oceni to podczas konsultacji.",
    },
  ];

  return (
    <Layout bg_color="purple" constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Laserowe Usuwanie Zmian Skórnych w Warszawie - twojeznamiona.pl"
        description="Laserowe Usuwanie Zmian Skórnych w Warszawie - Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych. Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych."
        keywords="usuwanie laserowe, nieinwazyjne, usuwanie kurzajek, brodawek, włókniaków, znamion, pieprzykow, piegów, pypciów, usuwanie brodawek z twarzy, wymrażanie kurzajek, wymrazanie brodawek"
      />
      <Hero
        h1="Laserowe Usuwanie Zmian Skórnych w Warszawie"
        oneLiner="Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych."
        oneLinerMore="Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych.Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
        type="laser"
      />
      <Offer className="home_offer" data={OFFERS} />
      <ImportantInfo
        h2="Laserowe Usuwanie Zmian Skórnych"
        className="home_important_info"
        data={INFOS}
      />

      <PriceList className="home_price_list" data={PRICES} ifProgramProfilaktykiAvailable/>

      <Contact className="home_contact" where="w Warszawie" city="Warszawa" />
      <Location
        className="home_location"
        coords={{
          lat: 52.234631817750476,
          lng: 20.959518637081253,
        }}
        googleMarker={{
          data: ["ul. Sokołowska 9/U-12", "01-142 Warszawa"],
          link: "https://g.page/twoje-znamiona-warszawa?share",
        }}
        additionalDetails="Centrum Badania Znamion znajduje się w tym samym pasażu co Auchan - Centrum Medyczne Medify.me. Przed Auchan są dostępne miejsca parkingowe."
        locationInfo={[
          "Centrum Medyczne Medify.me",
          "ul. Sokołowska 9/U-12",
          "Dzielnica - Wola",
          "01-142 Warszawa",
        ]}
        hoursInfo={["pon. - pt.: 08:00 - 20:30,", "sob. 9:00-14:00"]}
        transportationInfo={[
          "Parking podziemny - sklep Auchan w tym samym budynku",
          "Przystanek tramwajowy:",
          "Sokołowska",
          "Stacja metra: Młynów",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="warszawa"
        imageTitlePostfix="w Warszawie"
      />

      <Laser
        className="home_two_col_section_left_img with_no_padding_top"
        reverse={false}
      />

      <Reviews className="home_reviews" data={REVIEWS} />
      <Faq className="home_faq with_padding_top_bottom" data={FAQS} />
      <Surgery className="home_two_col_section_left_img" reverse={true} />
      <Melanoma className="home_melanoma" />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={true}
      />

      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta isLanding />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: {
            relativePath: { regex: "/Centrum Badania Znamion w Warszawie.jpg/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_1.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_2.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery4: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_4.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery5: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_5.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery6: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_6.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq4.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-4-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <WarszawaLaserPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            data.ImageGallery3.nodes[0],
            data.ImageGallery4.nodes[0],
            data.ImageGallery5.nodes[0],
            data.ImageGallery6.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
